<template>
  <div>
    <v-card class="primary-shade--light pa-3">
      <h1 class="text-center subtitle-4 black--text">المجهزين</h1>
      <v-row class="mt-5">
        <v-col md="4" cols="12">
          <div class="d-md-flex">
            <v-btn
              tile
              color="primary"
              class="ml-2 mb-4 mb-md-0 mb-sm-0"
              :block="isScreenXs"
              @click="addDialog.open = true"
            >
              اضافة مجهز<v-icon right> fa-plus </v-icon>
            </v-btn>
            <!-- <v-btn tile color="primary" class="ml-2 mb-4 mb-md-0 mb-sm-0" @click="goToInvoicePage"> فتح الفاتورة
                <v-icon right> fa-plus </v-icon>
              </v-btn> -->
            <v-btn
              tile
              color="success"
              :loading="xlsxData.downloadLoading"
              :block="isScreenXs"
              @click="getAllDataAxios"
            >
              تحميل اكسل <v-icon right> fa-download </v-icon>
            </v-btn>
          </div>
        </v-col>
        <v-spacer></v-spacer>
        <v-col md="4" cols="12">
          <div class="d-flex flex-row">
            <v-text-field v-model="table.search" label="البحث" outlined dense @keyup.enter="search()"></v-text-field>
            <button class="search-btn" @click="search()">
              <v-icon style="font-size: 18px; color: white"> fa-search </v-icon>
            </button>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-data-table
            :headers="table.headers"
            loading-text="جاري التحميل ... الرجاء الانتظار"
            :items="table.Data"
            :options.sync="tableOptions"
            :server-items-length="table.totalData"
            :loading="table.loading"
            class="elevation-1"
            :footer-props="{
              itemsPerPageOptions: [10, 50, 100],
            }"
          >
            <template slot="item._id" slot-scope="props">
              {{ table.totalData - props.index - (tableOptions.page - 1) * tableOptions.itemsPerPage }}
            </template>
            <template v-slot:item.actions="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="primary" class="ml-2" v-bind="attrs" size="20" v-on="on" @click="editItem(item)">
                    fa-edit
                  </v-icon>
                </template>
                <span>تعديل</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="#FF5252" v-bind="attrs" size="20" v-on="on" @click="deleteItem(item)">
                    fa-trash
                  </v-icon>
                </template>
                <span>حذف</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
    <!-- add dialog -->
    <v-dialog v-model="addDialog.open" max-width="800px">
      <v-card>
        <v-card-title>
          <span class="headline">اضافة</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form v-model="addDialog.isFormValidAdd">
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="addData.store_suppliers_name"
                    label="اسم المجهز"
                    :rules="rules.store_suppliers_name"
                    outlined
                    required
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="addData.store_suppliers_mobile"
                    label="هاتف المجهز"
                    :rules="rules.store_suppliers_mobile"
                    outlined
                    required
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="addData.store_suppliers_address"
                    label="عنوان المجهز"
                    :rules="rules.store_suppliers_address"
                    outlined
                    required
                    dense
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="ml-3">
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="addDialog.open = false"> الغاء </v-btn>
          <v-btn
            color="primary"
            :loading="addDialog.loading"
            :disabled="!addDialog.isFormValidAdd"
            @click="addSuppliers"
          >
            اضافة
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- add Class dialog -->
    <!-- edit Class dialog -->
    <v-dialog v-model="dialogEdit.open" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">تعديل</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form v-model="dialogEdit.isFormValid">
              <v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="editedItem.store_suppliers_name"
                      label="اسم المجهز"
                      :rules="rules.store_suppliers_name"
                      outlined
                      required
                      dense
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="editedItem.store_suppliers_mobile"
                      label="هاتف المجهز"
                      :rules="rules.store_suppliers_mobile"
                      outlined
                      required
                      dense
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="editedItem.store_suppliers_address"
                      label="عنوان المجهز"
                      :rules="rules.store_suppliers_address"
                      outlined
                      required
                      dense
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="ml-3">
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogEdit.open = false"> الغاء </v-btn>
          <v-btn
            color="primary"
            :loading="dialogEdit.loading"
            :disabled="!dialogEdit.isFormValid"
            @click="editItemConform"
          >
            تعديل
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- delete dialog -->
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="headline justify-center"> هل انت متأكد من حذف هذا العنصر ؟ </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogDelete = false"> الغاء </v-btn>
          <v-btn color="primary white--text" :loading="deleteItemLoading" @click="deleteItemConfirm"> حذف </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- End delete dailog -->
    <!--- Dailog for show info to user-->
    <v-dialog v-model="dialogData.open" max-width="500px">
      <v-toolbar :color="dialogData.color" dense></v-toolbar>
      <v-card>
        <v-card-title class="headline justify-center"> {{ dialogData.bodyText }} </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1 justify-start" text @click="dialogData.open = false"> تم </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import suppliersApi from '@/api/suppliers'
import { mdiCash, mdiCashMultiple } from '@mdi/js'

export default {
  data () {
    return {
      icons: {
        mdiCashMultiple,
        mdiCash,
      },

      rules: {
        store_suppliers_name: [value => !!value || 'الاسم مطلوب'],
        store_suppliers_mobile: [value => !!value || 'الهاتف مطلوب'],
      },

      addData: {
        store_suppliers_name: null,
        store_suppliers_mobile: null,
        store_suppliers_address: null,
      },

      deleteItemLoading: false,

      content_url: null,

      dialogDelete: false,

      dialogData: {
        open: false,
        color: 'primary',
        bodyText: 'test',
      },

      editedItem: {},

      dialogEdit: {
        open: false,
        isFormValid: false,
        loading: false,
      },

      xlsxData: {
        list: null,
        listLoading: true,
        downloadLoading: false,
        filename: 'المجهزين',
        autoWidth: true,
        bookType: 'xlsx',
      },

      allData: [],

      isScreenXs: false,

      deletedItem: {},

      tableOptions: {},

      addDialog: {
        open: false,
        isFormValidAdd: false,
        saveLoading: false,
        loading: false,
      },

      suppliersLoading: false,

      table: {
        search: null,
        totalData: 0,
        Data: [],
        loading: true,

        headers: [
          {
            text: '#',
            align: 'start',
            sortable: false,
            value: '_id',
            width: 1,
          },
          { text: 'اسم المجهز', sortable: false, value: 'store_suppliers_name' },
          { text: 'هاتف المجهز', sortable: false, value: 'store_suppliers_mobile' },
          { text: 'عنوان المجهز', sortable: false, value: 'store_suppliers_address' },
          { text: 'العمليات', value: 'actions', sortable: false },
        ],
      },
    }
  },
  watch: {
    '$route.query.search': {
      handler (search) {
        this.table.search = search
        this.getDataAxios(search)
      },
      deep: true,

      // immediate: true,
    },
  },

  created () {
    this.getDataAxios()
  },
  methods: {
    async getDataAxios () {
      let { search } = this.table
      this.table.loading = true
      let { page, itemsPerPage } = this.tableOptions
      if (!search) {
        search = ''
      }

      if (!page) {
        page = 1
      }
      if (!itemsPerPage) {
        itemsPerPage = 10
      }

      const response = await suppliersApi.get({
        search,
        page,
        limit: itemsPerPage,
      })

      if (response.status === 401) {
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.table.loading = false
        this.showDialogfunction(response.data.results, '#FF5252')
      } else {
        this.table.loading = false
        this.table.Data = response.data.results.data
        this.table.totalData = response.data.results.count
      }
    },

    editItem (item) {
      this.editedItem = { ...item }
      this.dialogEdit.open = true
    },

    async editItemConform () {
      this.dialogEdit.open = true
      this.dialogEdit.loading = true

      const response = await suppliersApi.edit({
        store_suppliers_name: this.editedItem.store_suppliers_name,
        store_suppliers_mobile: this.editedItem.store_suppliers_mobile,
        store_suppliers_address: this.editedItem.store_suppliers_address,
        id: this.editedItem._id,
      })

      if (response.status === 401) {
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.dialogEdit.open = false
        this.dialogEdit.loading = false
        this.showDialogfunction(response.data.results, '#FF5252')
      } else {
        this.dialogEdit.open = false
        this.dialogEdit.loading = false
        this.getDataAxios()
        this.showDialogfunction(response.data.results, 'primary')
      }
    },

    search () {
      this.$router.replace(
        {
          query: { search: this.table.search },
        },
        () => {},
      )
    },

    async addSuppliers () {
      this.addDialog.loading = true

      const response = await suppliersApi.add({
        store_suppliers_name: this.addData.store_suppliers_name,
        store_suppliers_mobile: this.addData.store_suppliers_mobile,
        store_suppliers_address: this.addData.store_suppliers_address,
      })

      if (response.status === 401) {
        this.addDialog.loading = false
        this.addDialog.open = false
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.addDialog.loading = false
        this.addDialog.open = false
        this.showDialogfunction(response.data.results, '#FF5252')
      } else {
        this.addDialog.loading = false
        this.addDialog.open = false
        this.showDialogfunction(response.data.results, 'primary')
        this.getDataAxios()
      }
    },

    deleteItem (item) {
      this.deletedItem = { ...item }
      this.dialogDelete = true
    },

    async deleteItemConfirm () {
      this.deleteItemLoading = true

      const response = await suppliersApi.remove(this.deletedItem._id)

      if (response.status === 401) {
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.deleteItemLoading = false
        this.showDialogfunction(response.data.results, '#FF5252')
      } else {
        this.deleteItemLoading = false
        this.dialogDelete = false
        this.getDataAxios()
        this.showDialogfunction(response.data.results, 'primary')
      }
    },

    showDialogfunction (bodyText, color) {
      this.dialogData.open = true
      this.dialogData.bodyText = bodyText
      this.dialogData.color = color
    },

    async getAllDataAxios () {
      this.xlsxData.downloadLoading = true

      const response = await suppliersApi.getAll()

      if (response.status === 401) {
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.xlsxData.downloadLoading = false
        this.showDialogfunction(response.data.results, '#FF5252')
      } else {
        this.allData = response.data.results
        this.handleDownload()
      }
    },

    handleDownload () {
      import('@/vendor/Export2Excel').then(excel => {
        const tHeader = ['اسم المجهز', 'هاتف المجهز', 'عنوان المجهز']
        const filterVal = ['store_suppliers_name', 'store_suppliers_mobile', 'store_suppliers_address']

        // const { list } = this
        const data = this.formatJson(filterVal, this.allData)
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: this.xlsxData.filename,
          autoWidth: this.xlsxData.autoWidth,
          bookType: this.xlsxData.bookType,
        })
        this.xlsxData.downloadLoading = false
      })
    },

    formatJson (filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]))
    },
  },
}
</script>
