import axios from 'axios'

class SuppliersApi {
  async get({ page, limit, search }) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .post(`/counting/store/suppliers/get`, {
        page,
        limit,
        search,
      })
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  async getSuppliersNameAndId() {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .get(`/counting/store/suppliers/getNameAndId`)
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  async getAll() {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .get(`/counting/store/suppliers/getAll`)
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  async add({ store_suppliers_name, store_suppliers_mobile, store_suppliers_address }) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .post(`/counting/store/suppliers/add`, {
        store_suppliers_name,
        store_suppliers_mobile,
        store_suppliers_address,
      })
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  async edit({ store_suppliers_name, store_suppliers_mobile, store_suppliers_address, id }) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .put(`/counting/store/suppliers/edit`, {
        store_suppliers_name,
        store_suppliers_mobile,
        store_suppliers_address,
        suppliers_id: id,
      })
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  async remove(id) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .delete(`/counting/store/suppliers/remove/suppliers_id/${id}`)
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }
}

export default new SuppliersApi()
